import React from 'react';
import { injectIntl } from 'react-intl';
import Paper from '@material-ui/core/Paper';

class StructOff extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    render() {

        return (
            <div className='StructOff'>
                <Paper elevation={9} className="delay-1s">
                    <div>
                        {this.props.children}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default injectIntl(StructOff);

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { browserHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import _ from 'underscore';
import FileUploader from 'react-firebase-file-uploader';
import {firebase} from '@firebase/app';
import '@firebase/storage';
import {toastr} from 'react-redux-toastr';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Patient extends React.Component {

    constructor(props){
        super(props);

        if(this.props.data && this.props.data.patients && this.props.data.patients[this.props.params.uid]){
            this.state = {
                ...this.props.data.patients[this.props.params.uid], 
                props: this.props,
                showNewConsultation: false,
                showNewPrescription: false,
                showNewExam: false,
                newConsultationDate: moment().format('DD/MM/YYYY'),
                newConsultationText: '',
                newPrescriptionDate: moment().format('DD/MM/YYYY'),
                newPrescriptionText: '',
                newExamDate: moment().format('DD/MM/YYYY'),
                newExamText: '',
                newFileDate: moment().format('DD/MM/YYYY'),
                newFileText: ''
            };
        }
        else {
            this.state = {
                props: this.props,
                showNewConsultation: false,
                showNewPrescription: false,
                showNewExam: false,
                newConsultationDate: moment().format('DD/MM/YYYY'),
                newConsultationText: '',
                newPrescriptionDate: moment().format('DD/MM/YYYY'),
                newPrescriptionText: '',
                newExamDate: moment().format('DD/MM/YYYY'),
                newExamText: '',
                newFileDate: moment().format('DD/MM/YYYY'),
                newFileText: ''
            };
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if(nextProps !== state.props){
            var newState = {};

            if(nextProps.data && nextProps.data.patients && nextProps.data.patients[nextProps.params.uid]){
                var client = nextProps.data.patients[nextProps.params.uid];
                newState = {...client};
                if(!newState.files){
                    newState.files = null;
                }
                if(!newState.consultations){
                    newState.consultations = null;
                }
            }

            newState.props = nextProps;

            return newState;
        }
        return null;
    }

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    getCleanedData(){
        var data = {...this.state};
        delete data.props;
        delete data.id;
        delete data.showNewConsultation;
        delete data.showNewPrescription;
        delete data.showNewExam;
        delete data.newConsultationDate;
        delete data.newConsultationText;
        delete data.newPrescriptionDate;
        delete data.newPrescriptionText;
        delete data.newExamDate;
        delete data.newExamText;
        delete data.newFileDate;
        delete data.newFileText;

        return data;
    }

    saveData(){
        var data = this.getCleanedData();

        firebaseEvents.savePatient(this.props.params.uid, data);
    }

    getAge(){
        if(this.state.birthDate){
            return moment().diff(moment(this.state.birthDate, 'DD/MM/YYYY'), 'years');
        }
        return null;
    }

    newConsultation(){
        firebaseEvents.newConsultation(this.props.params.uid, this.state.newConsultationDate, this.state.newConsultationText).then(()=>{
            this.setState({...this.state, showNewConsultation:false, newConsultationDate: moment().format('DD/MM/YYYY'), newConsultationText: ''});
        });
    }

    newPrescription(){
        firebaseEvents.newPrescription(this.props.params.uid, this.state.newPrescriptionDate, this.state.newPrescriptionText).then(()=>{
            this.setState({...this.state, showNewPrescription:false, newPrescriptionDate: moment().format('DD/MM/YYYY'), newPrescriptionText: ''});
        });
    }

    newExam(){
        firebaseEvents.newExam(this.props.params.uid, this.state.newExamDate, this.state.newExamText).then(()=>{
            this.setState({...this.state, showNewExam:false, newExamDate: moment().format('DD/MM/YYYY'), newExamText: ''});
        });
    }

    newFile(){
        // firebaseEvents.newFile(this.props.params.uid, this.state.newFileDate, this.state.newFileText).then(()=>{
        //     this.setState({...this.state, showNewFile:false, newFileDate: moment().format('DD/MM/YYYY'), newFileText: ''});
        // });
    }


    uploadFile(e){
        const { target: { files } } = e;
        var fileSizeGb = files[0].size/1000/1000/1000;
        
        if(fileSizeGb > 0.25){
            toastr.error(this.t('file.over.250MB'));
        }
        // compare to account max
        else {
            firebase.storage().ref('files/' + this.props.params.uid).listAll().then((t)=>{
                var sameNameExist = false;
                if(t.items && t.items.length){
                    t.items.forEach((i)=>{
                        if(!sameNameExist && i.name === files[0].name){
                            sameNameExist = true;
                        }
                    });
                }
                if(sameNameExist){
                    toastr.error(this.t('storage.filename.same.error')); 
                }
                else {
                    this.fileUploader.startUpload(files[0]);     
                }
            });
        }
    }

    handleUploadStart(){
        console.log('upload start');
    }

    handleProgress(progress){
        console.log('upload progress', progress);
    }

    handleUploadError(){
        toastr.error(this.t('error uploading file'));
    }
    handleUploadSuccess(filename){
        var fullPath = 'files/' + this.props.params.uid;
        
        firebase.storage().ref(fullPath).child(filename).getDownloadURL().then((url)=>{
            firebase.storage().ref(fullPath).child(filename).getMetadata().then((metadata)=>{
                firebaseEvents.addFile(
                    this.props.params.uid, 
                    {
                        firebaseStorage: true,
                        name: filename,
                        url: url,
                        fullPath: metadata.fullPath,
                        size: metadata.size/1000/1000/1000
                    }
                );
            });
        });
    }

    getConsultations(){
        if(this.state.consultations){
            var data = {...this.state.consultations};
            for(var i in data){
                data[i].id = i;
                data[i].date2 = moment(data[i].date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            var list = [], items = _.sortBy(data, 'date2');

            items.reverse();

            items.forEach((item)=>{
                // list.push(
                //     <li key={item.id}>
                //         <div>{item.date}</div>
                //         <div dangerouslySetInnerHTML={{__html:item.text}}/>
                //     </li>
                // );

                list.push(
                    <li key={item.id}>
                        <div>
                            <RadioGroup value={item.location} onChange={(event)=>{
                                firebaseEvents.changeConsultationLocation(event.target.value, this.props.params.uid, item.id);
                            }}>
                                <FormControlLabel value="medmulher" control={<Radio />} label="Medmulher" />
                                <FormControlLabel value="xclinic" control={<Radio />} label="X-clinic" />
                                <FormControlLabel value="online" control={<Radio />} label="Online" />
                            </RadioGroup>
                            <DatePicker
                                format="DD/MM/YYYY"
                                margin="normal"
                                label="Date"
                                inputVariant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={moment(item.date, 'DD/MM/YYYY').toDate()}
                                onChange={(date)=>{
                                    firebaseEvents.changeConsultationDate(date.format('DD/MM/YYYY'), this.props.params.uid, item.id);
                                }}
                                autoOk={true}
                                cancelLabel={null}
                                okLabel={null}
                            />
                            <a className="deleteConsult" onClick={()=>{
                                var that = this;
                                toastr.confirm('Are you sure you want to delete this consultation?', {
                                    onOk: () => {
                                        firebaseEvents.deleteConsultation(that.props.params.uid, item.id);
                                    },
                                    okText: 'YES',
                                    cancelText: 'CANCEL'
                                });
                            }}>
                                Delete consultation
                            </a>   
                        </div>
                        <div>
                            <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}}
                                label="Description" 
                                multiline
                                value={item.text.replace(/<br\/>/g, '\n')} 
                                onChange={(e)=>{ 
                                    firebaseEvents.changeConsultationText(e.target.value, this.props.params.uid, item.id);
                                }}
                            />
                        </div>
                    </li>
                );
            });

            return list;
        }
        return <li key="none" className="empty">No consultation found</li>;
    }

    getPrescriptions(){
        if(this.state.prescriptions){
            var data = {...this.state.prescriptions};
            for(var i in data){
                data[i].id = i;
                data[i].date2 = moment(data[i].date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            var list = [], items = _.sortBy(data, 'date2');

            items.reverse();

            items.forEach((item)=>{
                list.push(
                    <li key={item.id}>
                        <div>{item.date}</div>
                        <div dangerouslySetInnerHTML={{__html:item.text}}/>
                    </li>
                );
            });

            return list;
        }
        return <li key="none" className="empty">No prescription found</li>;
    }

    getExams(){
        if(this.state.exams){
            var data = {...this.state.exams};
            for(var i in data){
                data[i].id = i;
                data[i].date2 = moment(data[i].date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            var list = [], items = _.sortBy(data, 'date2');

            items.reverse();

            items.forEach((item)=>{
                list.push(
                    <li key={item.id}>
                        <div>{item.date}</div>
                        <div dangerouslySetInnerHTML={{__html:item.text}}/>
                    </li>
                );
            });

            return list;
        }
        return <li key="none" className="empty">No exam found</li>;
    }

    getFiles(){
        console.log('getFiles', this.state.files);
        if(this.state.files){
            var data = {...this.state.files};
            for(var i in data){
                data[i].id = i;
                data[i].date2 = moment(data[i].date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            var list = [], items = _.sortBy(data, 'date2');

            items.reverse();

            items.forEach((item)=>{
                list.push(
                    <li key={item.id}>
                        <div>{item.date}</div>
                        <div>
                            <input value={item.data.name} onChange={(e)=>{ firebaseEvents.changeFileName(e.target.value, this.props.params.uid, item.id); }} />
                        </div>
                        <div>
                            <a href={item.data.url} target="_blank">See file</a>
                        </div>
                        <div className="deleteFile">
                            <a onClick={()=>{ this.deleteFile(this.props.params.uid, item.id); }}>Delete file</a>
                        </div>
                    </li>
                );
            });

            return list;
        }
        return <li key="none" className="empty">No file found</li>;
    }

    getWarningDuplicate(){

        if(this.props.data && this.props.data.patients){
            
            for(var i in this.props.data.patients){
                if(i!==this.props.params.uid && this.props.data.patients[i].name.toLowerCase() === this.state.name.toLowerCase()){
                    return <div style={{color:'red'}}>A patient with this name is already present.</div>;
                }
            }
        }

        return null;
    }

    deleteFile(patientId, fileId){
        var that = this;
        toastr.confirm('Are you sure you want to delete this file?', {
            onOk: () => {

                firebaseEvents.deleteFile(patientId, fileId).then(()=>{
                    that.forceUpdate();
                });
            },
            okText: 'YES',
            cancelText: 'CANCEL'
        });
    }

    render() {

        return (
            <div className="Patient">
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={()=>browserHistory.push('/clients')}
                >
                    &laquo; Go back to patients list
                </Button>

                <h2>
                    <span>Patient: <b>{this.state.name}</b></span>

                    <a href="#details">Details</a>
                    <a href="#consultations">Consultations</a>
                    <a href="#prescriptions">Prescriptions</a>
                    <a href="#exams">Exams</a>
                    <a href="#files">Files</a>
                </h2>

                <div className="details" id="details">
                    <div className="general">
                    
                        <h3>General information</h3>
                        {this.getWarningDuplicate()}
                        <TextField
                            label="Name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.name}
                            onChange={(e)=>{
                                this.setState({...this.state, name: e.target.value});
                            }}
                            onBlur={this.saveData.bind(this)}
                        />

                        <DatePicker
                            format="DD/MM/YYYY"
                            margin="normal"
                            label="Birth date"
                            fullWidth
                            inputVariant="outlined"
                            size="small"
                            emptyLabel=""
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(this.state.birthDate)?moment(this.state.birthDate, 'DD/MM/YYYY').toDate():null}
                            onChange={(date)=>{
                                this.setState({...this.state, birthDate: date.format('DD/MM/YYYY')});
                            }}
                            onBlur={this.saveData.bind(this)}
                            autoOk={true}
                            cancelLabel={null}
                            okLabel={null}
                        />


                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Email" 
                            value={this.state.email} 
                            onChange={(e)=>{ this.setState({...this.state, email: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Phone" 
                            value={this.state.phone} 
                            onChange={(e)=>{ this.setState({...this.state, phone: e.target.value}); }}
                        />

                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="NIF" 
                            value={this.state.nif} 
                            onChange={(e)=>{ this.setState({...this.state, nif: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="SNS number" 
                            value={this.state.sns} 
                            onChange={(e)=>{ this.setState({...this.state, sns: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="BI/Passport" 
                            value={this.state.bi} 
                            onChange={(e)=>{ this.setState({...this.state, bi: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Insurance" 
                            value={this.state.insurance} 
                            onChange={(e)=>{ this.setState({...this.state, insurance: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Profession" 
                            value={this.state.profession} 
                            onChange={(e)=>{ this.setState({...this.state, profession: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Nationality" 
                            value={this.state.nationality} 
                            onChange={(e)=>{ this.setState({...this.state, nationality: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Language" 
                            value={this.state.language} 
                            onChange={(e)=>{ this.setState({...this.state, language: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Marital status" 
                            value={this.state.maritalStatus} 
                            onChange={(e)=>{ this.setState({...this.state, maritalStatus: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Address" 
                            value={this.state.address} 
                            onChange={(e)=>{ this.setState({...this.state, address: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Postal code" 
                            value={this.state.cp} 
                            onChange={(e)=>{ this.setState({...this.state, cp: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="City" 
                            value={this.state.city} 
                            onChange={(e)=>{ this.setState({...this.state, city: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Country" 
                            value={this.state.country} 
                            onChange={(e)=>{ this.setState({...this.state, country: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Referred by" 
                            value={this.state.referredby} 
                            onChange={(e)=>{ this.setState({...this.state, referredby: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Remarks" 
                            value={this.state.remarks} 
                            multiline
                            rows={5}
                            onChange={(e)=>{ this.setState({...this.state, remarks: e.target.value}); }}
                        />

                    </div>
                    <div className="history">
                        <h3>Clinical history</h3>


                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}}
                            label="Age" 
                            disabled
                            value={this.getAge()} 
                        />

                        
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="GS" 
                            value={this.state.gs} 
                            onChange={(e)=>{ this.setState({...this.state, gs: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Menarca" 
                            value={this.state.menarca} 
                            onChange={(e)=>{ this.setState({...this.state, menarca: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="I.O." 
                            value={this.state.io} 
                            onChange={(e)=>{ this.setState({...this.state, io: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Cycles" 
                            value={this.state.cycles} 
                            onChange={(e)=>{ this.setState({...this.state, cycles: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Contraception" 
                            value={this.state.contraception} 
                            onChange={(e)=>{ this.setState({...this.state, contraception: e.target.value}); }}
                        />

                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Gyno treatments" 
                            multiline
                            rows={5}
                            value={this.state.gynoTreatments} 
                            onChange={(e)=>{ this.setState({...this.state, gynoTreatments: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Dysmenorhea" 
                            multiline
                            rows={5}
                            value={this.state.dor} 
                            onChange={(e)=>{ this.setState({...this.state, dor: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Dyspareunia" 
                            value={this.state.dyspareunia} 
                            onChange={(e)=>{ this.setState({...this.state, dyspareunia: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Dyschezia" 
                            value={this.state.dyschezia} 
                            onChange={(e)=>{ this.setState({...this.state, dyschezia: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Dysuria" 
                            value={this.state.dysuria} 
                            onChange={(e)=>{ this.setState({...this.state, dysuria: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="DPC" 
                            value={this.state.dpc} 
                            onChange={(e)=>{ this.setState({...this.state, dpc: e.target.value}); }}
                        />

                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Menopause" 
                            value={this.state.menopause} 
                            onChange={(e)=>{ this.setState({...this.state, menopause: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Allergies" 
                            value={this.state.allergies} 
                            onChange={(e)=>{ this.setState({...this.state, allergies: e.target.value}); }}
                        />

                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="HPV vaccine" 
                            value={this.state.hpvvaccine} 
                            onChange={(e)=>{ this.setState({...this.state, hpvvaccine: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Smoker" 
                            value={this.state.smoker} 
                            onChange={(e)=>{ this.setState({...this.state, smoker: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="AP" 
                            multiline
                            rows={5}
                            value={this.state.ap} 
                            onChange={(e)=>{ this.setState({...this.state, ap: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="AC" 
                            multiline
                            rows={5}
                            value={this.state.ac} 
                            onChange={(e)=>{ this.setState({...this.state, ac: e.target.value}); }}
                        />
                        
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Breast and Gyno cancer" 
                            value={this.state.cancer} 
                            onChange={(e)=>{ this.setState({...this.state, cancer: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="Family Thromboembolism" 
                            value={this.state.thromboembolism} 
                            onChange={(e)=>{ this.setState({...this.state, thromboembolism: e.target.value}); }}
                        />
                        <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} onBlur={this.saveData.bind(this)}
                            label="AF" 
                            multiline
                            rows={5}
                            value={this.state.af} 
                            onChange={(e)=>{ this.setState({...this.state, af: e.target.value}); }}
                        />

                    </div>
                    
                </div>

                <div className="consultations" id="consultations">
                    <h3>
                        <span>Consultations</span>
                        <div>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={this.newConsultation.bind(this)}
                            >
                                New consultation
                            </Button>
                        </div>
                    </h3>
                    {
                        (this.state.showNewConsultation)?(

                            <div className="new">
                                <h4>New consultation</h4>
                                <div>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        label="Date"
                                        inputVariant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={(this.state.newConsultationDate)?moment(this.state.newConsultationDate, 'DD/MM/YYYY').toDate():moment().toDate()}
                                        onChange={(date)=>{
                                            this.setState({...this.state, newConsultationDate: date.format('DD/MM/YYYY')});
                                        }}
                                        autoOk={true}
                                        cancelLabel={null}
                                        okLabel={null}
                                    />

                                    <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}}
                                        label="Description" 
                                        multiline
                                        rows={10}
                                        value={this.state.newConsultationText} 
                                        onChange={(e)=>{ this.setState({...this.state, newConsultationText: e.target.value}); }}
                                    />
                                </div>
                                <div>
                                    <Button size="small" variant="contained" color="primary"
                                        onClick={this.newConsultation.bind(this)}
                                    >Save</Button>
                                    <Button size="small" variant="contained"
                                        onClick={()=>this.setState({...this.state, showNewConsultation:false})}
                                    >Cancel</Button>
                                </div>
                            </div>

                        ):null
                    }
                    <ul className="list">
                        {this.getConsultations()}
                    </ul>
                </div>

                <div className="prescriptions" id="prescriptions">
                    <h3>
                        <span>Prescriptions</span>
                        <div>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={()=>this.setState({...this.state, showNewPrescription:true})}
                            >
                                New prescription
                            </Button>
                        </div>
                    </h3>
                    {
                        (this.state.showNewPrescription)?(

                            <div className="new">
                                <h4>New prescription</h4>
                                <div>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        label="Date"
                                        inputVariant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={(this.state.newPrescriptionDate)?moment(this.state.newPrescriptionDate, 'DD/MM/YYYY').toDate():moment().toDate()}
                                        onChange={(date)=>{
                                            this.setState({...this.state, newPrescriptionDate: date.format('DD/MM/YYYY')});
                                        }}
                                        autoOk={true}
                                        cancelLabel={null}
                                        okLabel={null}
                                    />

                                    <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}}
                                        label="Description" 
                                        multiline
                                        rows={10}
                                        value={this.state.newPrescriptionText} 
                                        onChange={(e)=>{ this.setState({...this.state, newPrescriptionText: e.target.value}); }}
                                    />
                                </div>
                                <div>
                                    <Button size="small" variant="contained" color="primary"
                                        onClick={this.newPrescription.bind(this)}
                                    >Save</Button>
                                    <Button size="small" variant="contained"
                                        onClick={()=>this.setState({...this.state, showNewPrescription:false})}
                                    >Cancel</Button>
                                </div>
                            </div>

                        ):null
                    }
                    <ul className="list">
                        {this.getPrescriptions()}
                    </ul>
                </div>

                <div className="exams" id="exams">
                    <h3>
                        <span>Exams</span>
                        <div>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={()=>this.setState({...this.state, showNewExam:true})}
                            >
                                New exam
                            </Button>
                        </div>
                    </h3>
                    {
                        (this.state.showNewExam)?(

                            <div className="new">
                                <h4>New exam</h4>
                                <div>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        label="Date"
                                        inputVariant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={(this.state.newExamDate)?moment(this.state.newExamDate, 'DD/MM/YYYY').toDate():moment().toDate()}
                                        onChange={(date)=>{
                                            this.setState({...this.state, newExamDate: date.format('DD/MM/YYYY')});
                                        }}
                                        autoOk={true}
                                        cancelLabel={null}
                                        okLabel={null}
                                    />

                                    <TextField fullWidth margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}}
                                        label="Description" 
                                        multiline
                                        rows={10}
                                        value={this.state.newExamText} 
                                        onChange={(e)=>{ this.setState({...this.state, newExamText: e.target.value}); }}
                                    />
                                </div>
                                <div>
                                    <Button size="small" variant="contained" color="primary"
                                        onClick={this.newExam.bind(this)}
                                    >Save</Button>
                                    <Button size="small" variant="contained"
                                        onClick={()=>this.setState({...this.state, showNewExam:false})}
                                    >Cancel</Button>
                                </div>
                            </div>

                        ):null
                    }
                    <ul className="list">
                        {this.getExams()}
                    </ul>
                </div>

                <div className="files" id="files">
                    <h3>
                        <span>Files</span>
                        <div>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                            >
                                New file
                                <FileUploader
                                    ref={instance => { this.fileUploader = instance; } }
                                    accept="*"
                                    storageRef={firebase.storage().ref('files/' + this.props.params.uid)}
                                    onChange={this.uploadFile.bind(this)}
                                    onUploadStart={this.handleUploadStart.bind(this)}
                                    onUploadError={this.handleUploadError.bind(this)}
                                    onUploadSuccess={this.handleUploadSuccess.bind(this)}
                                    onProgress={this.handleProgress.bind(this)}
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                        width: '100%',
                                        opacity: 0
                                    }}
                                />
                            </Button>
                        </div>
                    </h3>
                    <ul className="list">
                        {this.getFiles()}
                    </ul>
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {
    return {
        data: state.app.data
    };
}

export default connect(mapStateToProps)(injectIntl(Patient));

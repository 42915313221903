import React from 'react';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import * as appactions from '../App/AppActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { browserHistory } from 'react-router';
import {toastr} from 'react-redux-toastr';
import _ from 'underscore';

class Dashboard extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            search: ''
        };
    }


    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    gotoPatient(e){
        browserHistory.push('/clients/' + e.target.getAttribute('data-patientid'));
    }

    deletePatient(e){
        var patientId = e.target.getAttribute('data-patientid');

        toastr.confirm('Are you sure you want to delete this patient?', {
            onOk: () => {
                
                toastr.confirm('Are you REALLY sure you want to delete this patient?', {
                    onOk: () => {
                        firebaseEvents.deletePatient(patientId);
                    },
                    okText: 'YES',
                    cancelText: 'CANCEL'
                });
            },
            okText: 'YES',
            cancelText: 'CANCEL'
        });
    }

    getPatients(){
        var list = [];

        if(this.props.data && this.props.data.patients){
            for(var i in this.props.data.patients){
                this.props.data.patients[i].id = i;
            }

            var patientList = _.sortBy(this.props.data.patients, 'name');

            patientList.forEach((patient)=>{
                var found = true;
                if(this.state.search !== ''){
                    found = true;
                    let searchTerms = this.state.search.split(' ');
                    searchTerms.forEach((term)=>{
                        if(patient.name.toLowerCase().search(term.toLowerCase())===-1){
                            found = false;
                        }
                    });
                    // if(patient.name.toLowerCase().search(this.state.search.toLowerCase())!==-1){
                    //     found = true;
                    // }
                }
                if(found){
                    list.push(
                        <li key={patient.id}>
                            <a onClick={this.gotoPatient.bind(this)} data-patientid={patient.id}>{patient.name}</a>
                            <a onClick={this.deletePatient.bind(this)} data-patientid={patient.id}>Delete</a>
                        </li>
                    );
                }
            });
        }

        if(list.length === 0){
            list.push(<li key="none" className="empty">No result found</li>);
        }

        return list;
    }

    newPatient(){
        firebaseEvents.newPatient();
    }
    
    render(){

        return (
            <div className="Dashboard">
                <div className="actions">
                    
                    <TextField
                        placeholder="Search"
                        className="search"
                        value={this.state.search}
                        onChange={(e)=>{
                            this.setState({...this.state, search: e.target.value});
                        }}
                    />
                    <div/>


                    <div className="nbPatients">
                        {(this.props.data && this.props.data.patients)? Object.keys(this.props.data.patients).length + ' patients':null}
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.newPatient.bind(this)}
                    >
                        New patient
                    </Button>
                </div>
                <ul className="list">
                    {this.getPatients()}
                </ul>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        data: state.app.data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard));

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from '../Header/Header';

class StructOn extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }


        
    render() {
        
        return (
            <div className='StructOn'>
                <Header />
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
        user: state.app.user,
        account: state.app.account
    };
}

export default connect(mapStateToProps)(injectIntl(StructOn));

import React from 'react';
import { render } from 'react-dom';
import Store from './configureStore';
import {firebase} from '@firebase/app';


// wrap your entire app tree in the ErrorBoundary provided

firebase.initializeApp({
    projectId: process.env.FIREBASE_PROJECTID,
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID
});

// Application Main CSS
require('../stylesheets/main.scss');

function run() {
    render(<Store />, document.getElementById('root'));
}

// Check if polyfill required
if (!global.Intl) {
    require.ensure([
        'intl',
        'intl/locale-data/jsonp/en.js'
    ], function (require) {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        run();
    });
}
else {
    run();
}


import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { browserHistory } from 'react-router';

class Header extends React.Component {


    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    logOut(){
        firebaseEvents.logOut();
    }
        
    render() {

        return (
            <div className='Header'>
                <h1 onClick={()=>browserHistory.push('/clients')}>Dra. Joana Faria</h1>
                <a onClick={this.logOut.bind(this)}>Log out</a>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
        account: state.app.account,
        user: state.app.user,
        routing: state.routing
    };
}

export default connect(mapStateToProps)(injectIntl(Header));

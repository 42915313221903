export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CONNECTED = 'CONNECTED';
export const DEFINE_DATA = 'DEFINE_DATA';
export const DEFINE_USER = 'DEFINE_USER';
export const DEFINE_USER_DATA = 'DEFINE_USER_DATA';
export const DEFINE_USER_ACCOUNTS = 'DEFINE_USER_ACCOUNTS';
export const DEFINE_USER_CHALLENGES = 'DEFINE_USER_CHALLENGES';
export const DEFINE_ACCOUNT = 'DEFINE_ACCOUNT';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const DEFINE_DISCOUNT_CODE = 'DEFINE_DISCOUNT_CODE';
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_WORK_MANAGER = 'SET_WORK_MANAGER';
export const GAPI_LOADED = 'GAPI_LOADED';
import * as app from './AppConstants';
import { engine } from '../../utils/engine';
import { diff } from 'deep-object-diff';

var activeUserId;

export function defineData(data) {
    return {
        type: app.DEFINE_DATA,
        data: data
    };
}

export function changeLocale(locale) {
    return {
        type: app.CHANGE_LOCALE,
        locale
    };
}

export function gapiLoad(isLoaded) {
    return {
        type: app.GAPI_LOADED,
        status: isLoaded
    };
}

export function connectionState(state) {
    return {
        type: app.CONNECTED,
        state
    };
}

export function defineUser(user) {
    return {
        type: app.DEFINE_USER,
        user: user
    };
}

export function defineUserData(data) {
    activeUserId = data.userId;
    localStorage.setItem('userData', JSON.stringify(data));
    return {
        type: app.DEFINE_USER_DATA,
        data: data
    };
}

export function defineUserAccounts(data) {
    localStorage.setItem('userAccounts', JSON.stringify(data));
    return {
        type: app.DEFINE_USER_ACCOUNTS,
        accounts: data
    };
}

export function defineUserChallenges(data) {
    localStorage.setItem('userChallenges', JSON.stringify(data));
    return {
        type: app.DEFINE_USER_CHALLENGES,
        data: data
    };
}

export function defineAccount(account) {
    return dispatch => {

        var accountId = null;
        if(account && account.id){
            accountId = account.id;
        }

        var saved = localStorage.getItem('estimated-' + accountId),
            estimated = JSON.parse(saved),
            shouldRecalculate = false;

        // set active user permissions on each task and event
        var isAdmin = false;
        if(account && account.users && account.users[activeUserId] && account.users[activeUserId].permissions && account.users[activeUserId].permissions.admin){
            isAdmin = true;
        }

        var canAdmin, canManage, canWork, canView;

        ['tasks', 'events'].forEach((type)=>{

            if(account && account[type]){

                for(var t in account[type]){
                    canAdmin = false;
                    canManage = false;
                    canWork = false;
                    canView = false;
                    
                    if(isAdmin || (account[type][t].permissions && account[type][t].permissions.owners && account[type][t].permissions.owners.indexOf(activeUserId) !== -1)){
                        canAdmin = true;
                        canManage = true;
                        canView = true;
                    }
                    else if(account[type][t].permissions && account[type][t].permissions.managers && account[type][t].permissions.managers.indexOf(activeUserId) !== -1){
                        canManage = true;
                        canView = true;
                    }
                    else if(account[type][t].permissions && account[type][t].permissions.viewers && account[type][t].permissions.viewers.indexOf(activeUserId) !== -1){
                        canView = true;
                    }

                    if(account[type][t].permissions && account[type][t].permissions.workers && account[type][t].permissions.workers.indexOf(activeUserId) !== -1){
                        if(type === 'tasks'){
                            canWork = true;
                        }
                        canView = true;
                    }

                    account[type][t].canAdmin = canAdmin;
                    account[type][t].canManage = canManage;
                    account[type][t].canWork = canWork;
                    account[type][t].canView = canView;
                }
            }
        });


        if(account && saved){

            for(var taskId in estimated.tasks){
                if(account && account.tasks && account.tasks[taskId]){
                    account.tasks[taskId].estimations = estimated.tasks[taskId].estimations;
                }
            }

            var firstEngineDate = null;

            for(var userId in estimated.users){
                if(account && account.users && account.users[userId]){
                    if(!firstEngineDate && estimated.users[userId].engineAvailabilities){
                        firstEngineDate = new Date(Object.keys(estimated.users[userId].engineAvailabilities)[0]);
                    }
                    account.users[userId].engineAvailabilities = estimated.users[userId].engineAvailabilities;
                    account.users[userId].lastEngineDate = estimated.users[userId].lastEngineDate;
                }
            }

            if(account.users && account.users[userId] && !account.users[userId].lastEngineDate){
                shouldRecalculate = true;
            }
            var today = new Date();
            today.setHours(0,0,0,0);

            if(!shouldRecalculate && (!firstEngineDate || firstEngineDate.getTime() < today.getTime())){
                shouldRecalculate = true;
            }

            var difference = diff(estimated, account);

            if(!shouldRecalculate && difference.tasks){

                for(var key in difference.tasks){
                    if(
                        !difference.tasks[key] ||
                        difference.tasks[key] === undefined ||
                        difference.tasks[key].index ||
                        difference.tasks[key].index>-1 ||
                        difference.tasks[key].parent ||
                        difference.tasks[key].priority ||
                        difference.tasks[key].priority>-1 ||
                        difference.tasks[key].skill ||
                        difference.tasks[key].status ||
                        difference.tasks[key].childrens ||
                        difference.tasks[key].startedWorkingAt ||
                        difference.tasks[key].deadline ||
                        difference.tasks[key].delayUntil ||
                        difference.tasks[key].forcedUser ||
                        difference.tasks[key].forcedUser === undefined ||
                        (!account.tasks[key].childrens && difference.tasks[key].minEffort) ||
                        (!account.tasks[key].childrens && difference.tasks[key].minEffort>-1) ||
                        (!account.tasks[key].childrens && difference.tasks[key].maxEffort) ||
                        (!account.tasks[key].childrens && difference.tasks[key].maxEffort>-1) ||
                        difference.tasks[key].dependencies ||
                        (difference.tasks[key].permissions && difference.tasks[key].permissions.workers)
                    ){
                        
                        if(difference.tasks[key] && difference.tasks[key].dependencies){
                            for(var i in difference.tasks[key].dependencies){
                                if(difference.tasks[key].dependencies[i] !== undefined){
                                    shouldRecalculate = true;
                                    break;
                                }
                            }
                            if(shouldRecalculate) break;
                        }
                        else {
                            shouldRecalculate = true;
                            break;
                        }
                    }
                    else if(account.tasks[key].childrens){
                        estimated.tasks[key] = account.tasks[key];
                    }
                }
            }

            if(!shouldRecalculate && difference.events){
                for(var key2 in difference.events){
                    if(
                        !difference.events[key2] ||
                        difference.events[key2] === undefined ||
                        difference.events[key2].index ||
                        difference.events[key2].index>-1 ||
                        difference.events[key2].allDay ||
                        difference.events[key2].endDate ||
                        difference.events[key2].endHour ||
                        difference.events[key2].parent ||
                        difference.events[key2].participants ||
                        difference.events[key2].recurrency ||
                        difference.events[key2].startDate ||
                        difference.events[key2].startHour

                    ){
                        shouldRecalculate = true;
                        break;
                    }
                }
            }
            if(!shouldRecalculate && difference.daysoff){
                shouldRecalculate = true;
            }
            if(!shouldRecalculate && difference.skills){

                for(var key3 in difference.skills){
                    if(
                        !difference.skills[key3] ||
                        difference.skills[key3] === undefined ||
                        difference.skills[key3].factor
                    ){
                        shouldRecalculate = true;
                    }
                }
            }
            if(!shouldRecalculate && difference.users){

                for(var key4 in difference.users){
                    if(
                        !difference.users[key4] ||
                        difference.users[key4] === undefined ||
                        difference.users[key4].availability ||
                        difference.users[key4].skills ||
                        Object.prototype.hasOwnProperty.call(difference.users[key4], 'daysoff')
                    ){
                        shouldRecalculate = true;
                    }
                }
            }
        }
        else {
            shouldRecalculate = true;
        }

        // shouldRecalculate = false;

        estimated = {...account};

        engine(estimated, shouldRecalculate).then(()=>{
            localStorage.setItem('estimated-' + estimated.id, JSON.stringify(estimated));
            dispatch({
                type: app.DEFINE_ACCOUNT,
                account: estimated
            });
        });

    };
}

export function showLoader() {
    return {
        type: app.SHOW_LOADER
    };
}

export function hideLoader() {
    return {
        type: app.HIDE_LOADER
    };
}


export function updateFilters(filters, accountId){
    localStorage.setItem('filters-' + accountId, JSON.stringify(filters));
    return {
        type: app.UPDATE_FILTERS,
        filters: JSON.stringify(filters)
    };
}

export function defineDiscountCode(code){
    return {
        type: app.DEFINE_DISCOUNT_CODE,
        code: code
    };
}


export function removeDiscountCode(){
    return {
        type: app.REMOVE_DISCOUNT_CODE
    };
}

export function setWorkManager(data){
    return {
        type: app.SET_WORK_MANAGER,
        data
    };
}
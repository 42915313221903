import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropRight from '@material-ui/icons/ArrowRight';
import UserIcon from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import SelectedIcon from '@material-ui/icons/Done';
import $ from 'jquery';

export function getTaskRisks(task){
    var taskAtDanger = false,
        taskAtRisk = false,
        taskNoSkill = (!task.childrens && !task.skill),
        taskNoEffort = (!task.childrens && ((!task.minEffort && !task.maxEffort) || (task.minEffort === '0.00' && task.maxEffort === '0.00'))),
        taskEstimationError = (!task.childrens && task.estimations && task.estimations.error) ? task.estimations.error : null;

    if(
        task.status !== 'done' && 
        task.deadline && task.estimations && task.estimations.expectedAt &&
        moment(task.deadline).isBefore(moment(task.estimations.expectedAt))
    ){
        taskAtDanger = true;
    }
    else if(
        task.status !== 'done' && 
        task.deadline && task.estimations && task.estimations.endAt &&
        moment(task.deadline).isBefore(moment(task.estimations.endAt))
    ){
        taskAtRisk = true;
    }

    return {
        taskAtDanger,
        taskAtRisk,
        taskNoSkill,
        taskNoEffort,
        taskEstimationError
    };
}

export function getGroupByTitle(groupData, parent){
    var userGroups = [];

    _.each(groupData, (group, groupId)=> {
        if(group.parent === parent){
            userGroups.push({...group, id: groupId});
        }
    });

    return _.sortBy(userGroups, 'title');
}

export function getCustomSelect(type, accountData, parent, searchText, values, onChange, limitToItems){
    if(accountData){
        var group;
        if(type === 'users'){
            group = accountData.userGroups;
        }
        else if(type === 'skills'){
            group = accountData.skillGroups;
        }

        var groupList = [];
        var userGroups = getGroupByTitle(group, parent);

        for(var i in userGroups){
            var sublevel = getCustomSelect(type, accountData, userGroups[i].id, searchText, values, onChange, limitToItems);
            if(sublevel){
                groupList.push(
                    <li key={'userGroup-' + userGroups[i].id}>
                        <span>{userGroups[i].title}</span>
                        <ArrowDropRight />
                        {sublevel}
                    </li>
                );
            }
        }

        var results = groupList.concat(getRows(type, accountData, parent, searchText, values, onChange, limitToItems));

        if(results.length > 0){
            return (
                <ul>
                    {results}
                </ul>
            );
        }

    }
    return null;
}

function getRows(type, accountData, groupId, searchText, values, onChange, limitToItems){

    var data, sortName;
    if(type === 'users'){
        data = accountData.users;
        sortName = 'displayName';
    }
    else if(type === 'skills'){
        data = accountData.skills;
        sortName = 'name';
    }

    var list = [],
        items = [];

    _.each(data, (item,itemId)=>{
        if(type !== 'users' || !item.viewer){
            items.push({...item, id: itemId});
        }
    });

    items = _.sortBy(items, sortName);

    _.each(items, (item)=>{
        if(item && (!limitToItems || limitToItems.indexOf(item.id) !== -1)){
            if(item.group === groupId && item[sortName] && item[sortName].search(new RegExp(encodeURIComponent(searchText), 'gi')) !== -1){
                
                if(type === 'users'){
                    var isSelected = (values && values.indexOf(item.id) !== -1);
                    list.push(<li key={'memberselect-' + item.id} data-userid={item.id} onClick={()=>onChange(item.id)}>{getUserAvatar(item.id, isSelected, accountData)}<span>{item.displayName}</span></li>);
                }
                else if(type === 'skills'){
                    var selectedIcon = (values && values.indexOf(item.id) !== -1) ? <SelectedIcon />:null;
                    list.push(
                        <li key={'skillselect-' + item.id} data-skillid={item.id} onClick={()=>onChange(item.id)}>{selectedIcon}<span>{item.name}</span></li>
                    );
                }
            }
        }
    });
    
    return list;
}


export function getUserAvatar(userId, isSelected, accountData){
    if(isSelected){
        return <Avatar key={userId} className="avatar emptyAvatar selectedAvatar"><CheckIcon /></Avatar>;
    }
    else if(accountData.users[userId] && accountData.users[userId].avatar){
        return <Avatar key={userId} className="avatar" src={accountData.users[userId].avatar} />;
    }

    return <Avatar key={userId} className="avatar emptyAvatar"><UserIcon /></Avatar>;
}

export function checkTaskAgainstLimit(task, childList, parentIds, limitViewToTask){
    return !(limitViewToTask &&
        task.id !== limitViewToTask &&
        (
            !parentIds[task.id] ||
            parentIds[task.id].indexOf(limitViewToTask) === -1
        ) &&
        (!childList || childList.indexOf(limitViewToTask) === -1));


}

export function checkTaskAgainstFilters(filters, task, group, childList, taskList, parentIds, ignoreList, limitViewToTask){
    if(task && task.childrens){

        for(var child in childList){
            
            var currentTask = null;

            for(var item in taskList){
                if(taskList[item].id === childList[child]){
                    currentTask = taskList[item];
                    break; 
                }
            }

            var currentChildList = [];
            if(currentTask && currentTask.childrens){
                for(var key1 in parentIds){
                    if(parentIds[key1].indexOf(currentTask.id) !== -1){
                        currentChildList.push(key1);
                    }
                }
            }

            var res = checkTaskAgainstFilters(filters, currentTask, null, currentChildList, taskList, parentIds, ignoreList, limitViewToTask);

            if(res){
                return true;
            }
        }

        return false;
    }

    if(!group){
        group = JSON.parse(filters);
    }

    if(!group.id){

        var groupAndor = group.andor;

        for(var key in group){
            if(key === 'andor'){ continue; }

            var result = checkTaskAgainstFilters(filters, task, group[key], childList, taskList, parentIds, ignoreList, limitViewToTask);

            if(groupAndor === 'and' && !result){
                return false;
            }
            else if(groupAndor === 'or' && result){
                return true;
            }
        }

        if(groupAndor === 'and'){
            return true;
        }
        else if(groupAndor === 'or'){
            return false;
        }

    }
    else {
        return checkTaskFilter(task, group, taskList, parentIds, ignoreList);
    }
}


function checkTaskFilter(task, filter, taskList, parentIds, ignoreList){
    if(task){
        if(ignoreList && ignoreList.indexOf(filter.id) !== -1){
            return true;
        }
        else if(filter.id === 'status'){
            if(task.tasktype !== 'task'){
                return true;
            }
            else if(filter.type === 'is'){
                return (filter.value.indexOf(task.status) !== -1);
            }
            else if(filter.type === 'isnot'){
                return (filter.value.indexOf(task.status) === -1);   
            }
        }
        else if(filter.id === 'tasktype'){
            if(filter.type === 'is'){
                return (filter.value.indexOf(task.tasktype) !== -1);
            }
            else if(filter.type === 'isnot'){
                return (filter.value.indexOf(task.tasktype) === -1);   
            }
        }
        else if(filter.id === 'title'){
            var title = task.title;
            if(task.tasktype === 'event'){
                title = task.name;
            }
            if(filter.type === 'is'){
                return (title === filter.value);
            }
            else if(filter.type === 'isnot'){
                return (title !== filter.value);
            }
            else if(filter.type === 'contains'){
                return (title.match(new RegExp(encodeURIComponent(filter.value), 'gi')) !== null);
            }
            else if(filter.type === 'doesnotcontain'){
                return (title.match(new RegExp(encodeURIComponent(filter.value), 'gi')) === null);
            }
        }
        else if(filter.id === 'directParent'){
            if(!task.parent){
                return false;
            }
            
            var parentTitle = '';

            for(var t in taskList){
                if(taskList[t].id === task.parent){
                    parentTitle = taskList[t].title;
                    break;
                }
            }

            if(filter.type === 'is'){
                return (parentTitle === filter.value);
            }
            else if(filter.type === 'isnot'){
                return (parentTitle !== filter.value);
            }
            else if(filter.type === 'contains'){
                return (parentTitle.match(new RegExp(encodeURIComponent(filter.value), 'gi')) !== null);
            }
            else if(filter.type === 'doesnotcontain'){
                return (parentTitle.match(new RegExp(encodeURIComponent(filter.value), 'gi')) === null);
            }
        }
        else if(filter.id === 'parent'){
            if(!task.parent){
                return false;
            }
            
            var parentTitles = [];

            for(var t2 in taskList){
                if(parentIds[task.id] && parentIds[task.id].indexOf(taskList[t2].id) !== -1){
                    parentTitles.push(taskList[t2].title);
                }
            }

            if(filter.type === 'is'){
                return (parentTitles.indexOf(filter.value) !== -1);
            }
            else if(filter.type === 'isnot'){
                return (parentTitles.indexOf(filter.value) === -1);
            }
            else if(filter.type === 'contains'){
                return (
                    _.find(parentTitles, (title)=>{
                        return title.match(new RegExp(encodeURIComponent(filter.value), 'gi')) !== null;
                    }) !== undefined
                );
            }
            else if(filter.type === 'doesnotcontain'){
                return (
                    _.find(parentTitles, (title)=>{
                        return title.match(new RegExp(encodeURIComponent(filter.value), 'gi')) !== null;
                    }) === undefined
                );
            }
        }
        else if(filter.id === 'affected'){

            if(task.tasktype === 'event' && (!task.participants || !task.participants.length === 0)){
                return false;
            }

            var affected = [];

            if(task.tasktype === 'task'){

                if(task.status === 'inprogress'){
                    affected = [task.userWorking];
                }
                else if(task.status !== 'done'){
                    if(task.forcedUser){
                        affected = [task.forcedUser];
                    }
                    else if(task.estimations && task.estimations.userId){
                        affected = [task.estimations.userId];
                    }
                }
                else if(task.doneBy){
                    affected = [task.doneBy];
                }

            }
            else if(task.tasktype === 'event'){
                affected = task.participants;
            }

            if(filter.type === 'is'){
                return (_.intersection(affected, filter.value).length > 0);
            }
            else if(filter.type === 'isnot'){
                return !(_.intersection(affected, filter.value).length > 0);
            }

            return false;

        }
        else if(filter.id === 'skill'){
            if(task.tasktype !== 'task'){
                return false;
            }
            else if(filter.type === 'is'){
                return (filter.value.indexOf(task.skill) !== -1);
            }
            else if(filter.type === 'isnot'){
                return (filter.value.indexOf(task.skill) === -1);   
            }
            else if(filter.type === 'hasValue'){
                return !(!task.skill);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.skill);
            }
        }
        else if(filter.id === 'priority'){
            if(task.tasktype !== 'task'){
                return false;
            }
            else {
                var priority;
                switch(task.priority){
                case 0:
                    priority = 'Normal';
                    break;
                case 1:
                    priority = 'Medium';
                    break;
                case 2:
                    priority = 'High';
                    break;
                case 3:
                    priority = 'Urgent';
                    break;
                default:
                    priority = 'Normal';
                    break;
                }

                if(filter.type === 'is'){
                    return (filter.value.indexOf(priority) !== -1);
                }
                else if(filter.type === 'isnot'){
                    return (filter.value.indexOf(priority) === -1);   
                }
            }
        }
        else if(filter.id === 'risk'){
            if(task.tasktype !== 'task'){
                return false;
            }
            else {
                var risks = getTaskRisks(task),
                    risk;

                if(risks.taskAtDanger){
                    risk = 'Danger';
                }
                else if(risks.taskAtRisk || risks.taskNoSkill || risks.taskNoEffort || risks.taskEstimationError){
                    risk = 'Warning';
                }
                else {
                    risk = 'None';
                }

                if(filter.type === 'is'){
                    return (filter.value.indexOf(risk) !== -1);
                }
                else if(filter.type === 'isnot'){
                    return (filter.value.indexOf(risk) === -1);   
                }
            }
        }
        else if(filter.id === 'mineffort'){
            if(task.tasktype !== 'task'){
                return false;
            }

            if(filter.type === 'lowerthan'){
                return (parseFloat(task.minEffort) < parseFloat(filter.value));
            }
            else if(filter.type === 'higherthan'){
                return (parseFloat(task.minEffort) > parseFloat(filter.value));
            }
            else if(filter.type === 'is'){
                return (parseFloat(filter.value) === parseFloat(task.minEffort));
            }
            else if(filter.type === 'hasValue'){
                return !(!task.minEffort);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.minEffort);
            }
        }
        else if(filter.id === 'maxeffort'){
            if(task.tasktype !== 'task'){
                return false;
            }

            if(filter.type === 'lowerthan'){
                return (parseFloat(task.maxEffort) < parseFloat(filter.value));
            }
            else if(filter.type === 'higherthan'){
                return (parseFloat(task.maxEffort) > parseFloat(filter.value));
            }
            else if(filter.type === 'is'){
                return (parseFloat(filter.value) === parseFloat(task.maxEffort));
            }
            else if(filter.type === 'hasValue'){
                return !(!task.maxEffort);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.maxEffort);
            }
        }
        else if(filter.id === 'expectedDeliveryDate'){

            if(
                (task.tasktype === 'event') ||
                (task.tasktype === 'task' && (!task.estimations || !task.estimations.expectedAt))
            ){
                return false;
            }

            if(filter.type === 'before'){
                return moment(task.estimations.expectedAt).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return moment(task.estimations.expectedAt).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.estimations.expectedAt);
            }
        }
        else if(filter.id === 'earliestDeliveryDate'){

            if(
                (task.tasktype === 'event') ||
                (task.tasktype === 'task' && (!task.estimations || !task.estimations.earliestAt))
            ){
                return false;
            }

            if(filter.type === 'before'){
                return moment(task.estimations.earliestAt).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return moment(task.estimations.earliestAt).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.estimations.earliestAt);
            }
        }
        else if(filter.id === 'latestDeliveryDate'){

            if(
                (task.tasktype === 'event') ||
                (task.tasktype === 'task' && (!task.estimations || !task.estimations.endAt))
            ){
                return false;
            }

            if(filter.type === 'before'){
                return moment(task.estimations.endAt).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return moment(task.estimations.endAt).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.estimations.endAt);
            }
        }
        else if(filter.id === 'dependency'){
            if(task.tasktype === 'event'){
                return false;
            }

            if(filter.type === 'hasValue'){
                return !(!task.dependencies);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.dependencies);
            }
        }
        else if(filter.id === 'delayDate'){

            if(task.tasktype === 'event'){
                return false;
            }

            if(filter.type === 'before'){
                return task.delayUntil && moment(task.delayUntil).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return task.delayUntil && moment(task.delayUntil).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.delayUntil);
            }
            else if(filter.type === 'hasValue'){
                return !(!task.delayUntil);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.delayUntil);
            }
        }
        else if(filter.id === 'timeworked'){
            if(task.tasktype !== 'task'){
                return false;
            }

            var timeworked=0;
            if(task.workingTime){
                for(var i in task.workingTime){
                    timeworked += parseFloat(task.workingTime[i].hours);
                }
            }

            if(filter.type === 'lowerthan'){
                return (parseFloat(timeworked) < parseFloat(filter.value));
            }
            else if(filter.type === 'higherthan'){
                return (parseFloat(timeworked) > parseFloat(filter.value));
            }
            else if(filter.type === 'is'){
                return (parseFloat(filter.value) === parseFloat(timeworked));
            }
            else if(filter.type === 'hasValue'){
                return !(!task.workingTime);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.workingTime);
            }
        }
        else if(filter.id === 'attachments'){
            if(task.tasktype === 'event'){
                return false;
            }

            if(filter.type === 'hasValue'){
                return !(!task.files);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.files);
            }
        }
        else if(filter.id === 'eventStartDate'){

            if(
                (task.tasktype === 'task')
            ){
                return false;
            }

            if(filter.type === 'before'){
                return moment(task.startDate).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return moment(task.startDate).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.startDate);
            }
        }
        else if(filter.id === 'eventEndDate'){

            if(
                (task.tasktype === 'task')
            ){
                return false;
            }

            if(filter.type === 'before'){
                return moment(task.endDate).isBefore(filter.value);
            }
            else if(filter.type === 'after'){
                return moment(task.endDate).isAfter(filter.value);   
            }
            else if(filter.type === 'on'){
                return (filter.value === task.endDate);
            }
        }
        else if(filter.id === 'recurrentevent'){

            if(
                (task.tasktype === 'task')
            ){
                return false;
            }

            if(filter.type === 'hasValue'){
                return !(!task.recurrency);
            }
            else if(filter.type === 'hasNoValue'){
                return (!task.recurrency);
            }
        }
    }

    return false;
}



export function getParentChildIds(list, onlyTasks){


    var result;
    var newList = [];
    list.forEach((t)=>{
        newList.push({id: t.id, childrens: t.childrens});
    });
    var listString = JSON.stringify(newList);
    if(onlyTasks){
        listString += 'onlyTasks';
    }
    if(window.parentsChilds[listString]){
        result = window.parentsChilds[listString];
    }
    else {

        var tempParents = {},
            tempChildrens = {};

        newList.forEach((currentTask)=>{
            if(currentTask.childrens){
                tempChildrens[currentTask.id] = [];
                currentTask.childrens.forEach((child)=>{
                    if(child && child.id !== currentTask.id){
                        tempParents[child.id] = [currentTask.id];
                        if(!onlyTasks || child.type === 'task'){
                            tempChildrens[currentTask.id].push(child.id);
                        }
                    }
                });
            }
        });

        var getChilds = (child)=>{
            var l = [];
            if(tempParents[child]){
                tempParents[child].forEach((c)=>{
                    l.push(c);
                    l = l.concat(getChilds(c));
                });
            }
            return l;
        };


        for(var tempChild in tempParents){
            tempParents[tempChild] = getChilds(tempChild);
        }
        
        var changes = 1;
        while(changes>0){
            changes = 0;

            for(var tempCh in tempChildrens){
                tempChildrens[tempCh].forEach((child)=>{
                    if(tempChildrens[child]){
                        tempChildrens[child].forEach((child2)=>{
                            if(tempChildrens[tempCh].indexOf(child2) === -1){
                                tempChildrens[tempCh].push(child2);
                                changes++;
                            }
                        });
                    }
                });
            }
        }
        
    
        result =  {
            parents: tempParents,
            childs: tempChildrens
        };
        window.parentsChilds[listString] = result;
    }
    return result;
}

export function startDragSidebar(){
    window.onmousemove = (e) => {
        var width = window.innerWidth - e.clientX;
        if(width < 620){ width = 620; }
        else if(width > (window.innerWidth-20)){ width = window.innerWidth-20; }
        $('.sidebar').css('width', width + 'px');
        $('.handle').css('right', width-5 + 'px');
        $('.sidebarCloseBtn').css('right', width + 'px');
    };

    $('body').on('touchmove', (e) => {
        var width = window.innerWidth - e.targetTouches[0].clientX;
        if(width < 620){ width = 620; }
        else if(width > (window.innerWidth-20)){ width = window.innerWidth-20; }
        $('.sidebar').css('width', width + 'px');
        $('.handle').css('right', width-5 + 'px');
        $('.sidebarCloseBtn').css('right', width + 'px');
    });
}

export function getBaseLocation(props){
    if(props.location.query.parentId){
        return {id: props.location.query.parentId, title: getTaskPath(props)};
    }
    else if(
        (
            !props.location.query.parentId &&
            (
                (props.user && props.user.data && props.user.data.permissions && props.user.data.permissions.admin) ||
                (props.account.basePermissions && props.account.basePermissions.owners && props.account.basePermissions.owners.indexOf(props.user.data.userId) !== -1) ||
                (props.account.basePermissions && props.account.basePermissions.managers && props.account.basePermissions.managers.indexOf(props.user.data.userId) !== -1)
            )
        )
    ){
        return {id: 'base', title: '---Top level of the workspace---'};
    }
    return null;
}

export function getTaskPath(props){
    let taskId = props.location.query.parentId;
    if(props.account.tasks[taskId]){
        var parentsPath = [props.account.tasks[taskId].title],
            currentTask = taskId;

        while(props.account.tasks[currentTask].parent){
            parentsPath.push(props.account.tasks[props.account.tasks[currentTask].parent].title);
            currentTask = props.account.tasks[currentTask].parent;
        }
        parentsPath = parentsPath.reverse().join('/');

        return parentsPath;
    }
    return null;
}
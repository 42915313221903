
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import classnames from 'clsx';
import { IntlProvider } from 'react-intl';

import * as appactions from './AppActions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as firebaseEvents from '../../utils/firebaseEvents';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1E4FA2',
            light: '#243758'
        },
        secondary: {
            main: '#F0562C'
        },
        text: {
            primary: '#333'
        }
    },
    typography: {
        fontFamily: ['"Open Sans"', 'sans-serif']
    }
});

class App extends React.Component {

    componentDidMount(){
        // var that = this;

        firebaseEvents.defineFirebaseEvents(this);

    }

    render() {
        let locale = this.props.app.locale || 'en',
            translations = require('../../../locales/' + locale + '.json');
        

        return (
            <IntlProvider locale={locale} messages={translations}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className={classnames('App', {active: this.props.app.account && this.props.app.user && this.props.app.user.data})}>
                            <div className={classnames('AppContent')}>
                                {this.props.children}
                            </div>
                        </div>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </IntlProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        routing: state.routing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
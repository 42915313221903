import {firebase} from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';
import '@firebase/messaging';
import { browserHistory } from 'react-router';
import moment from 'moment';

var database,
    storage,
    messaging,
    appComp,
    authUser;

console.log(storage, messaging);


var db = {
    v: 'value',
    r: (p)=>database.ref(p),
    set: (p, v)=>db.r(p).set(v),
    equal: (p, o, v, f)=>db.r(p).orderByChild(o).equalTo(v).once(db.v).then(f),
    order: (p, o, s, f)=>db.r(p).orderByChild(o).startAt(s).once(db.v).then(f),
    update: (p, u)=>db.r(p).update(u),
    push: (p, d)=>db.r(p).push(d),
    pushkey: (p)=>db.push(p).key,
    off: (p)=>db.r(p).off(db.v),
    on: (p, f)=>db.r(p).on(db.v, f),
    once: (p)=>db.r(p).once(db.v),
    remove: (p)=>db.r(p).remove()
};

export function defineFirebaseEvents(appComponent){
    appComp = appComponent;

    // Auth state change handler for login/logout
    firebase.auth().onAuthStateChanged((user) => {

        // localStorage.clear();
        // authUser = {...user, uid:'ij7FlxlKytfKI1AYArDAyi9eFdw2',email:'joel@notiondesigngroup.com'};
        authUser = user;

        appComponent.props.actions.defineUser(authUser);

        if(authUser){
            syncData();
            // checkUserHasAccount(authUser);                
            browserHistory.push(appComponent.props.routing.locationBeforeTransitions.pathname + appComponent.props.routing.locationBeforeTransitions.search);
        }
    });
    database = firebase.database();
    storage = firebase.storage();

    if (firebase.messaging.isSupported()){
        messaging = firebase.messaging();
    }

}

export function syncData(){

    db.on('/', (data) => {
        appComp.props.actions.defineData(data.val());
    });

}

export function newPatient(){
    var patientId = db.pushkey('/patients');
    db.set('/patients/' + patientId, {name: 'New patient'});
    browserHistory.push('/clients/' + patientId);
}

export function deletePatient(patientId){
    db.set('/patients/' + patientId, null);
    browserHistory.push('/clients');
}

export function savePatient(patientId, data){
    db.set('/patients/' + patientId, data);
}

export function newConsultation(patientId, date, text){
    var consultationId = db.pushkey('/patients/' + patientId + '/consultations');
    return db.set('/patients/' + patientId + '/consultations/' + consultationId, {date: date, text: text.replace(/\n/g, '<br/>')});
}


export function newPrescription(patientId, date, text){
    var prescriptionId = db.pushkey('/patients/' + patientId + '/prescriptions');
    return db.set('/patients/' + patientId + '/prescriptions/' + prescriptionId, {date: date, text: text.replace(/\n/g, '<br/>')});
}


export function addFile(patientId, data){
    var fileId = db.pushkey('/patients/' + patientId + '/files');
    return db.set('/patients/' + patientId + '/files/' + fileId, {date: moment().format('DD/MM/YYYY'), data: data});
}


export function newExam(patientId, date, text){
    var examId = db.pushkey('/patients/' + patientId + '/exams');
    return db.set('/patients/' + patientId + '/exams/' + examId, {date: date, text: text.replace(/\n/g, '<br/>')});
}



export function logOut(){

    console.log('test');
    
    appComp.props.actions.defineData(null);
    appComp.props.actions.defineUser(null);

    firebase.auth().signOut().then(()=>{
        console.log('test2');
        appComp.props.actions.defineUser(null);
        appComp.props.actions.defineData(null);

        for(var i in localStorage){
            if(i.indexOf('firebase:authUser') !== -1){
                localStorage.removeItem(i);
            }
        }
    });
}

export function deleteFile(patientId, fileId) {
    return db.set('/patients/' + patientId + '/files/' + fileId, null);
}

export function changeFileName(val, patientId, fileId) {
    return db.set('/patients/' + patientId + '/files/' + fileId + '/data/name', val);
}

export function changeConsultationDate(date, patientId, consultId) {
    return db.set('/patients/' + patientId + '/consultations/' + consultId + '/date', date);
}

export function changeConsultationLocation(location, patientId, consultId) {
    return db.set('/patients/' + patientId + '/consultations/' + consultId + '/location', location);
}
export function changeConsultationText(text, patientId, consultId) {
    return db.set('/patients/' + patientId + '/consultations/' + consultId + '/text', text);
}

export function deleteConsultation(patientId, consultId) {
    console.log(patientId, consultId);
    return db.set('/patients/' + patientId + '/consultations/' + consultId, null);
}
import * as constants from './AppConstants';


const app = (state = {
    user:null,
    data: null,
}, action) => {
    switch (action.type) {


    case constants.DEFINE_USER:
        return { ...state, user: action.user };

    case constants.DEFINE_DATA:
        return { ...state, data: action.data };
    default:
        return state;
    }
};

export default app;



import _  from 'underscore';
import {getParentChildIds} from './utils';

const Promise = require('es6-promise').Promise;

const todayFormatted = getFormattedDate(new Date());

/*eslint-disable */

export function engine(accountData, shouldRecalculate){
    
    return new Promise((resolve)=>{
        if(shouldRecalculate){
            // console.time('test0');
            // console.log('test0');
            console.time('engine');
            // init all variables
            let taskList = [],
                parentIds = {},
                childrenIds = {};
            const waitingList = [];
            let userBucket = {};
            const resolvedDependencies = {};
            let datesLoadedUntil = new Date();
            
            // for each user skill we calculate the multiplication factor for efforts
            for(let userId in accountData.users){
                if(!accountData.users[userId].viewer){
                    userBucket[userId] = {skills: calculateUserSkillMultiplicator(accountData.users[userId].skills, accountData.skills), dates:{}};
                }
            }
            // defining parent ids of tasks in a list to be used later
            let list = getOrderedTaskList(accountData.tasks);
            const temp = getParentChildIds(list, true);
            parentIds = temp.parents;
            childrenIds = temp.childs;
            
            // if task has parentS and that parentS have dependencies
            // we should add them to the task also
            // console.log('test1');
            let task1;
            for(let taskId1 in accountData.tasks){
                task1 = accountData.tasks[taskId1];

                if(task1.childrens && task1.dependencies){

                    childrenIds[taskId1].forEach((depId)=>{
                        accountData.tasks[depId].dependenciesCalc = accountData.tasks[depId].dependenciesCalc || [];
                        accountData.tasks[depId].dependenciesCalc = accountData.tasks[depId].dependenciesCalc.concat(task1.dependencies);
                    });

                    task1.dependencies.forEach((depId2)=>{
                        accountData.tasks[depId2].dependentOnThisCalc = accountData.tasks[depId2].dependentOnThisCalc || [];
                        accountData.tasks[depId2].dependentOnThisCalc = accountData.tasks[depId2].dependentOnThisCalc.concat(childrenIds[taskId1]);
                    });
                }
            }
            // debugger;
            list = getOrderedTaskList(accountData.tasks);
            // console.log('test2');
            let taskId, index = 0;
            // put all active and valid tasks in taskList
            list.forEach((task)=>{
                taskId = task.taskId;

                // we only push tasks that are not done and valid and don't have childrens
                if(task.status !== 'done' && !task.childrens && task.skill && task.maxEffort && parseFloat(task.maxEffort) > 0){

                    // if task has parentS and that parentS have delay date
                    // we should add them to the task also
                    task.delayUntilCalc = task.delayUntil;
                    if(task.parent){

                        if(parentIds[taskId]){
                            parentIds[taskId].forEach((currentParent)=>{
                                if(accountData.tasks[currentParent].delayUntil){
                                    if(!task.delayUntilCalc || new Date(accountData.tasks[currentParent].delayUntil).getTime() > new Date(task.delayUntilCalc).getTime()){
                                        task.delayUntilCalc = accountData.tasks[currentParent].delayUntil;
                                    }
                                }
                            });
                        }
                    }

                    if(task.delayUntilCalc === undefined){
                        delete task.delayUntilCalc;
                    }



                    // if task is depending on taskS that have childrens, all the childrens should be added as dependency of the current task
                    // task.dependenciesCalc = task.dependenciesCalc || (task.dependencies && task.dependencies.length) ? task.dependencies.join().split(','):[];
                    task.dependentOnThisCalc = task.dependentOnThisCalc || task.dependentOnThis || [];
                    task.dependenciesCalc = task.dependenciesCalc || [];
                    if(task.dependencies && task.dependencies.length){
                        task.dependenciesCalc = task.dependenciesCalc.concat(task.dependencies);
                    }

                    task.dependenciesCalc.forEach((currentDep)=>{

                        if(accountData.tasks[currentDep] && !accountData.tasks[currentDep].childrens){
                            task.dependenciesCalc.push(currentDep);
                        }
                        else if(childrenIds[currentDep]) {
                            childrenIds[currentDep].forEach((tid)=>{

                                task.dependenciesCalc.push(tid);
                                if(taskList[tid]){
                                    if(!taskList[tid].dependentOnThisCalc){
                                        taskList[tid].dependentOnThisCalc = [];
                                    }
                                    taskList[tid].dependentOnThisCalc.push(taskId);
                                }
                                else {
                                    if(!accountData.tasks[tid].dependentOnThisCalc){
                                        accountData.tasks[tid].dependentOnThisCalc = [];
                                    }
                                    accountData.tasks[tid].dependentOnThisCalc.push(taskId);
                                }
                            });
                        }
                    });

                    task.index = index;
                    // if(accountData.tasks[taskId].dependenciesCalc){ task.dependenciesCalc = accountData.tasks[taskId].dependenciesCalc; }
                    // if(accountData.tasks[taskId].dependentOnThisCalc){ task.dependentOnThisCalc = accountData.tasks[taskId].dependentOnThisCalc; }

                    taskList.push(task);

                    index++;
                }
                    // we also add to the resolved dependencies the tasks that cannot be calculated
                    // and done tasks that have dependentOnThis we add this task in resolvedDependencies with the date of yesterday
                // we don't put tasks that have childrens are they will be used in a separated path
                else {
                    const newD = new Date();
                    newD.setDate(newD.getDate() -1);
                    resolvedDependencies[taskId] = getFormattedDate(newD);
                }
            });
            taskList.forEach((t)=>{
                if(accountData.tasks[t.id].dependenciesCalc && accountData.tasks[t.id].dependenciesCalc.length){
                    t.dependenciesCalc = t.dependenciesCalc.concat(accountData.tasks[t.id].dependenciesCalc);
                }
                if(accountData.tasks[t.id].dependentOnThisCalc && accountData.tasks[t.id].dependentOnThisCalc.length){
                    t.dependentOnThisCalc = t.dependentOnThisCalc.concat(accountData.tasks[t.id].dependentOnThisCalc);
                }

                var depList = [];
                t.dependenciesCalc.forEach((d)=>{
                    if(depList.indexOf(d) === -1){
                        depList.push(d);
                    }
                });
                t.dependenciesCalc = depList;

                var depOnList = [];
                t.dependentOnThisCalc.forEach((d1)=>{
                    if(depOnList.indexOf(d1) === -1){
                        depOnList.push(d1);
                    }
                });
                t.dependentOnThisCalc = depOnList;
            });
            // console.log('test3');
            // order tasks by priorities
            taskList = _.sortBy(taskList, (task)=>{
                let multiplier = 0;

                switch(task.priority){
                    case 1:
                        multiplier += 30000;
                        break;
                    case 2:
                        multiplier += 20000;
                        break;
                    case 3:
                        multiplier += 10000;
                        break;
                    default:
                        multiplier += 40000;
                        break;
                }

                if(task.status === 'inprogress'){
                    multiplier = multiplier / 100;
                }

                return task.index + multiplier;
            });

            let lastLoopStatus = '';
            let addDatesToUsersData;
            let amountAddDates = 0;
            // console.log('test4');
            // loop on tasks until taskList and waitingList are empty
            while(taskList.length>0 || waitingList.length>0){
                // console.log('test-0');
                if(lastLoopStatus ===  taskList.length + '/' + waitingList.length){
                    break;
                }
                lastLoopStatus =  taskList.length + '/' + waitingList.length;

                
                // if the dates bucket of a user is less than 30 dates, we add 6 month of planning
                for(let user in userBucket) {
                    if(Object.keys(userBucket[user].dates).length < 30){
                        // console.log('test2-0');
                        addDatesToUsersData = addDatesToUsers(userBucket, accountData, datesLoadedUntil);
                        // console.log('test2-1');
                        userBucket = addDatesToUsersData.userBucket;
                        datesLoadedUntil = addDatesToUsersData.datesLoadedUntil;
                        amountAddDates++;
                        break;
                    }
                }
                // console.log('test-1');
                if(amountAddDates > 10){
                    break;
                }

                // init the possible start date and vars for the task to treat
                let possibleStartDate = new Date(),
                    taskToTreat,
                    taskToTreatFromWaitingListIndex = null;
                // console.log('test-2');
                
                // check if task in waiting list or not
                if(waitingList.length>0){

                    //for each task from the waiting list, test if dependencies are resolved
                    // if yes, define it as the task to treat
                    for(let i = 0; i < waitingList.length; ++i){
                        possibleStartDate = new Date();

                        if(!hasUnresolvedDependencies(waitingList[i], resolvedDependencies, (resolved)=>{
                            // define the possible start date at the latest resolved dependency
                            if(new Date(resolved).getTime() > possibleStartDate.getTime()){
                                possibleStartDate = new Date(resolved);
                            }
                        })){
                            // define taskToTreat from waiting list as dependencies are satisfied
                            taskToTreatFromWaitingListIndex = i;
                            taskToTreat = waitingList[i];
                            break;
                        }
                    }
                }
                
                // if the task to treat has not be defined from the waiting list, proceed with the task list
                if(taskToTreatFromWaitingListIndex === null && taskList.length > 0){

                    // check if task has a non resolved dependency
                    if(hasUnresolvedDependencies(taskList[0], resolvedDependencies, (resolved)=>{
                        // define the possible start date at the latest resolved dependency
                        if(new Date(resolved).getTime() > possibleStartDate.getTime()){
                            possibleStartDate = new Date(resolved);
                        }
                    })){
                        // put the task in the waiting list as it has an unsatisfied dependency and remove it from the task list
                        waitingList.push(taskList[0]);
                        taskList.splice(0,1);
                        // and restart the while loop
                        continue;
                    }

                    // define the taskToTreat from task list first element
                    taskToTreat = taskList[0];
                }
                // console.log('test-3');

                if(!possibleStartDate){
                    possibleStartDate = new Date();
                }

                // we can now treat the task
                // console.time('test1');

                if(taskToTreat){
                    // console.log('test--0');
                    const taskTreatment = treatTask(taskToTreat, userBucket, possibleStartDate);

                    // console.timeEnd('test1');
                    
                    if(taskTreatment){
                        // console.log('test--1');
                        userBucket = taskTreatment.userBucket;

                        // log updates
                        // updates[taskToTreat.taskId] = taskTreatment.estimations;
                        accountData.tasks[taskToTreat.taskId].estimations = taskTreatment.estimations;
                        // console.log('test--2');
                        //set treated task in resolved dependencies if she has
                        if(taskToTreat.dependentOnThisCalc || taskTreatment.estimations.error){
                            resolvedDependencies[taskToTreat.taskId] = taskTreatment.dependenciesCanStartOn;
                        }
                        // console.log('test--3');
                        // remove treated task from his list
                        if(taskToTreatFromWaitingListIndex !== null){
                            waitingList.splice(taskToTreatFromWaitingListIndex, 1);
                        }
                        else {
                            taskList.splice(0,1);
                        }
                        // console.log('test--4');
                    }
                }
                // console.log('test-4');
                
            }
            // console.log('test5');
            
            // when all the tasks that don’t have childrens are calculated,
            // we can make a 2nd pass to calculate dates for the tasks that have childrens
            let parentTask;
            let estimations;

            for(let parentTaskId in accountData.tasks){
                parentTask = accountData.tasks[parentTaskId];

                if(parentTask.childrens){
                    estimations = {
                        startAt: null,
                        earliestAt: null,
                        expectedAt: null,
                        endAt: null
                    };

                    if(childrenIds[parentTaskId]){
                        childrenIds[parentTaskId].forEach((childTaskId)=>{

                            if(accountData.tasks[childTaskId] && accountData.tasks[childTaskId].estimations){
                                if(estimations.startAt === null){
                                    estimations.startAt = accountData.tasks[childTaskId].estimations.startAt;
                                }
                                else if(accountData.tasks[childTaskId].estimations.startAt && new Date(accountData.tasks[childTaskId].estimations.startAt).getTime() < new Date(estimations.startAt).getTime()){
                                    estimations.startAt = accountData.tasks[childTaskId].estimations.startAt;
                                }

                                if(estimations.earliestAt === null){
                                    estimations.earliestAt = accountData.tasks[childTaskId].estimations.earliestAt;
                                }
                                else if(accountData.tasks[childTaskId].estimations.earliestAt && new Date(accountData.tasks[childTaskId].estimations.earliestAt).getTime() > new Date(estimations.earliestAt).getTime()){
                                    estimations.earliestAt = accountData.tasks[childTaskId].estimations.earliestAt;
                                }

                                if(estimations.expectedAt === null){
                                    estimations.expectedAt = accountData.tasks[childTaskId].estimations.expectedAt;
                                }
                                else if(accountData.tasks[childTaskId].estimations.expectedAt && new Date(accountData.tasks[childTaskId].estimations.expectedAt).getTime() > new Date(estimations.expectedAt).getTime()){
                                    estimations.expectedAt = accountData.tasks[childTaskId].estimations.expectedAt;
                                }

                                if(estimations.endAt === null){
                                    estimations.endAt = accountData.tasks[childTaskId].estimations.endAt;
                                }
                                else if(accountData.tasks[childTaskId].estimations.endAt && new Date(accountData.tasks[childTaskId].estimations.endAt).getTime() > new Date(estimations.endAt).getTime()){
                                    estimations.endAt = accountData.tasks[childTaskId].estimations.endAt;
                                }
                            }
                        });

                        parentTask.estimations = estimations;

                    }
                }
            }

            for(let u in userBucket){
                accountData.users[u].engineAvailabilities = userBucket[u].dates;
                accountData.users[u].lastEngineDate = Object.keys(userBucket[u].dates)[Object.keys(userBucket[u].dates).length-1];
            }

            console.timeEnd('engine');
        }
        
        resolve();
    });
}


function addDatesToUsers(userBucket, accountData, datesLoadedUntil){
    // console.log('test3-0');
    const startDate = new Date(datesLoadedUntil);
    startDate.setHours(0,0,0);

    datesLoadedUntil.setDate(datesLoadedUntil.getDate() + 365);
    const diff = 365;
    const tempObj = [];
    const currentDate = new Date(startDate.getTime());
    let weekday = currentDate.getDay() - 1;
    if(weekday<0) weekday = 6;
    let date;
    let i = 0;
    for(; i<diff; i++){
        date = getFormattedDate(currentDate);
        tempObj.push({date, weekday});
        currentDate.setDate(currentDate.getDate()+1);
        weekday++;
        if(weekday === 7) weekday=0;
    }
    
    // console.log('test3-1');
    let userAvailabilityOnDay;
    let weekdayAvailability;
    let dayoff;
    let dayoffStartDate;
    let dayoffEndDate;
    let event;

    for(let userId in userBucket){
        // console.log('test4-0');
        userAvailabilityOnDay = accountData.users[userId].availability;
        
        // console.log('test4-0b');
        if(userAvailabilityOnDay){
            tempObj.forEach((tempObjEl)=>{

                // console.log('test5-0', userAvailabilityOnDay, tempObjEl, tempObjEl.weekday);
                weekdayAvailability = userAvailabilityOnDay[tempObjEl.weekday];
                // console.log('test5-1');
                if(weekdayAvailability !== 0){
                    // console.log('test5-2');
                    userBucket[userId].dates[tempObjEl.date] = weekdayAvailability;
                }
                // console.log('test5-3');
            });
            // console.log('test4-1');
            // remove global daysoff
            for(let l in accountData.daysoff){
                dayoff = accountData.daysoff[l];

                dayoffStartDate = new Date(dayoff.startDate);
                dayoffEndDate = new Date(dayoff.endDate);

                do {
                    date = getFormattedDate(dayoffStartDate);
                    if(userBucket[userId].dates[date]){
                        delete userBucket[userId].dates[date];
                    }
                    dayoffStartDate.setDate(dayoffStartDate.getDate()+1);
                } while(dayoffStartDate.getTime() <= dayoffEndDate.getTime());
            }
            // console.log('test4-2');
            // remove user daysoff
            for(let f in accountData.users[userId].daysoff){
                dayoff = accountData.users[userId].daysoff[f];

                dayoffStartDate = new Date(dayoff.startDate);
                dayoffEndDate = new Date(dayoff.endDate);

                while(dayoffStartDate.getTime() <= dayoffEndDate.getTime()){
                    if(userBucket[userId].dates[getFormattedDate(dayoffStartDate)]){
                        delete userBucket[userId].dates[getFormattedDate(dayoffStartDate)];
                    }
                    dayoffStartDate.setDate(dayoffStartDate.getDate() + 1);
                }
            }
            // remove events hours
            // console.log('test4-3');
            let diffWeeks;
            for(let ei in accountData.events){

                event = accountData.events[ei];

                // check if user is participant
                if(event.participants && event.participants.indexOf(userId) !== -1){

                    // case of events with recurrency
                    if(event.recurrency){

                        const recurrencyStart = new Date(event.startDate);
                        let firstWeek = true;
                        const daysMap = [event.recurrency.Sun, event.recurrency.Mon, event.recurrency.Tue, event.recurrency.Wed, event.recurrency.Thu, event.recurrency.Fri, event.recurrency.Sat];
                        let nbHours = 24;

                        if(!event.allDay){
                            nbHours = Math.abs(new Date('01/01/2019 '+event.endHour+':00') - new Date('01/01/2019 '+event.startHour+':00')) / 36e5;
                        }
                        const eventRecurrencyEveryXweeks = event.recurrency.everyXweeks;

                        diffWeeks = Math.floor((datesLoadedUntil.getTime()-recurrencyStart.getTime())/604800000);

                        let m = 0;
                        const ml = diffWeeks;
                        for(; m<ml; m++){

                            let startAtIndex = 0;
                            if(firstWeek){
                                startAtIndex = recurrencyStart.getDay();
                                firstWeek = false;
                            }

                            let a = startAtIndex;
                            const al = 7;
                            for(; a < al; ++a){

                                const tempDate = recurrencyStart;
                                tempDate.setDate(tempDate.getDate() + (a - recurrencyStart.getDay()));
                                const formattedRecurrencyStart = getFormattedDate(tempDate);

                                if(daysMap[a] && userBucket[userId].dates[formattedRecurrencyStart]){
                                    userBucket[userId].dates[formattedRecurrencyStart] -= nbHours;
                                    userBucket[userId].dates[formattedRecurrencyStart] = parseFloat(userBucket[userId].dates[formattedRecurrencyStart].toFixed(2));
                                    if(userBucket[userId].dates[formattedRecurrencyStart] <= 0){
                                        delete userBucket[userId].dates[formattedRecurrencyStart];
                                    }

                                }
                            }

                            recurrencyStart.setDate(recurrencyStart.getDate()+(eventRecurrencyEveryXweeks*7));
                        }

                    }
                    // cases without recurrency
                    else {

                        // only take care of the event that are between the dates added (6months)
                        if(
                            new Date(event.startDate).getTime() < datesLoadedUntil.getTime() &&
                            new Date(event.endDate).getTime() >= startDate.getTime()
                        ){


                            // cases where event is all day long
                            if(event.allDay){

                                // cases on one day
                                if(event.startDate === event.endDate){
                                    if(userBucket[userId].dates[event.startDate]){
                                        delete userBucket[userId].dates[event.startDate];
                                    }
                                }
                                // cases on multiple days
                                else {
                                    const eventStartDate = new Date(event.startDate),
                                        eventEndDate = new Date(event.endDate);

                                    while(eventStartDate.getTime() <= eventEndDate.getTime()){
                                        if(userBucket[userId].dates[getFormattedDate(eventStartDate)]){
                                            delete userBucket[userId].dates[getFormattedDate(eventStartDate)];
                                        }

                                        eventStartDate.setDate(eventStartDate.getDate() + 1);
                                    }
                                }

                            }
                            // cases where event is with hours
                            else {

                                const eventStart = new Date(event.startDate + 'T' + event.startHour + ':00Z'),
                                    eventEnd = new Date(event.endDate + 'T' + event.endHour + ':00Z');

                                // cases on one day
                                if(event.startDate === event.endDate){

                                    const diff2 = (eventEnd.getTime() - eventStart.getTime()) / 3600000;

                                    if(userBucket[userId].dates[event.startDate]){
                                        userBucket[userId].dates[event.startDate] -= diff2;
                                        userBucket[userId].dates[event.startDate] = parseFloat(userBucket[userId].dates[event.startDate].toFixed(2));
                                        if(userBucket[userId].dates[event.startDate] <= 0){
                                            delete userBucket[userId].dates[event.startDate];
                                        }
                                    }

                                }
                                // cases on multiple days
                                else {

                                    let diffHours,
                                        endOfDay,
                                        eventStart2Form;

                                    while(eventStart.getTime() <= eventEnd.getTime()){

                                        eventStart2Form = getFormattedDate(eventStart);

                                        if(eventStart2Form === getFormattedDate(eventEnd)){
                                            diffHours = (eventEnd.getTime()-eventStart.getTime())/3600000;
                                        }
                                        else {
                                            endOfDay = new Date(eventStart);
                                            endOfDay.setDate(endOfDay.getDate()+1);
                                            endOfDay.setHours(0,0,0);

                                            diffHours = (endOfDay.getTime()-eventStart.getTime())/3600000;
                                        }

                                        if(userBucket[userId].dates[eventStart2Form]){
                                            userBucket[userId].dates[eventStart2Form] -= diffHours;
                                            userBucket[userId].dates[eventStart2Form] = parseFloat(userBucket[userId].dates[eventStart2Form].toFixed(2));
                                            if(userBucket[userId].dates[eventStart2Form] <= 0){
                                                delete userBucket[userId].dates[eventStart2Form];
                                            }
                                        }

                                        eventStart.setDate(eventStart.getDate() + 1);
                                        eventStart.setHours(0,0,0);
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
        // console.log('test4-4');
    }
    // console.log('test3-2');
    return {
        userBucket: userBucket,
        datesLoadedUntil: datesLoadedUntil
    };
}

function hasUnresolvedDependencies(task, resolvedDependencies, possibleStartDateFunction){
    let hasUnresolvedDependencies = false;

    task.dependenciesCalc.forEach((depC)=>{
        const resolved = resolvedDependencies[depC];
        if(!resolved){
            hasUnresolvedDependencies = true;
        }
        if(possibleStartDateFunction) possibleStartDateFunction(resolved);
    });

    return hasUnresolvedDependencies;
}

function calculateUserSkillMultiplicator(userSkills, accountSkills){

    const skillData = {};
    let skillLevel;

    for(let skillId in userSkills){
        skillLevel = userSkills[skillId];
        // if user has skill we add and calculate the multiplication factor
        if(skillLevel !== 0){
            let multiplicator;
            const factor = accountSkills[skillId].factor;

            if(skillLevel < 5){
                multiplicator = factor - (((factor-1)/4)*(skillLevel-1));
            }
            else {
                multiplicator = 1 - (((1 - (1/factor))/5)*(skillLevel-5));
            }
            skillData[skillId] = multiplicator;
        }
    }
    return skillData;
}

function treatTask(taskToTreat, userBucket, possibleStartDate){
    // console.log('test---0');

    if(taskToTreat === null) return;

    const estimations = {};

    let user, multiplicator, nbAverageHoursNeeded, currentDate, estDate;

    // initialising the average effort of the task
    const averageEffort = (parseFloat(taskToTreat.minEffort) + parseFloat(taskToTreat.maxEffort)) / 2;

    // if task delay date is after possibleStartDate, increase it
    const testDate = new Date(taskToTreat.delayUntilCalc);
    if(testDate.getTime() > possibleStartDate.getTime()){
        possibleStartDate = testDate;
    }
    //set possibleStartDate at 00h00
    possibleStartDate = new Date(possibleStartDate.toDateString());
    // console.log('test---1');
    // if the task has a user forced it would be this one
    if(taskToTreat.forcedUser){
        estimations.userId = taskToTreat.forcedUser;
    }
    else {

        // we calculate the best expected date for each user
        for(let userId in userBucket){
            if(taskToTreat.permissions && taskToTreat.permissions.workers && taskToTreat.permissions.workers.indexOf(userId) !== -1){
                user = userBucket[userId];

                // if the user has the required skill
                if(user.skills[taskToTreat.skill]){

                    // we define how many hours he needs to perform the task
                    multiplicator = user.skills[taskToTreat.skill];
                    nbAverageHoursNeeded = averageEffort*multiplicator;

                    // we go through his dates bucket
                    for(currentDate in user.dates){
                        // if the current date is after the possible start date of the task

                        if(new Date(currentDate).getTime() >= possibleStartDate.getTime()){

                            // we remove the value of the date bucket from the avg
                            nbAverageHoursNeeded -= user.dates[currentDate];

                            // if the average is equal or lower than 0 it means we arrived to the expected finish date of this user
                            if(nbAverageHoursNeeded <= 0){

                                // if this date is before the previous calculated date we set it with the user id
                                estDate = new Date(currentDate);

                                if(!estimations.expectedAt || estDate.getTime() < estimations.expectedAt.getTime()){
                                    estimations.expectedAt = estDate;
                                    estimations.userId = userId;
                                }

                                break;
                            }
                        }
                    }

                }
            }
        }

    }
    // console.log('test---2');
    if(estimations.userId){
        // console.log('test----0');
        // we need now to calculate all estimations with this user and remove from his dates bucket
        const userData = userBucket[estimations.userId];
        if(userData){
            // console.log(userData, taskToTreat.skill);
            multiplicator = userData.skills[taskToTreat.skill];
            // console.log('test----1');
            // if the user doesn't have the skill, the multiplicator is set to 1
            if(!multiplicator) multiplicator = 1;

            nbAverageHoursNeeded = averageEffort*multiplicator;
            
            let nbMinHoursNeeded = parseFloat(taskToTreat.minEffort) * multiplicator,
                nbMaxHoursNeeded = parseFloat(taskToTreat.maxEffort) * multiplicator,
                expectedFinishDateRest,
                currDateJs;
            for(currentDate in userData.dates){
                currDateJs = new Date(currentDate);
                // if the current date is after the possible start date of the task
                if(currDateJs.getTime() >= possibleStartDate.getTime()){
                    if(!estimations.startAt) estimations.startAt = currentDate;

                    // we remove the value of the date bucket from the avg
                    nbAverageHoursNeeded -= userData.dates[currentDate];
                    nbMinHoursNeeded -= userData.dates[currentDate];
                    nbMaxHoursNeeded -= userData.dates[currentDate];

                    // if the numbers are equal or lower than 0 it means we arrived to the finish dates
                    if(nbAverageHoursNeeded <= 0 && !expectedFinishDateRest){
                        estimations.expectedAt = currentDate;
                        expectedFinishDateRest = parseFloat(Math.abs(nbAverageHoursNeeded).toFixed(2));
                    }
                    if(nbMinHoursNeeded <= 0 && !estimations.earliestAt){
                        estimations.earliestAt = currentDate;
                    }
                    if(nbMaxHoursNeeded <= 0 && !estimations.endAt){
                        estimations.endAt = currentDate;
                    }

                    // if all dates are found we can stop the loop
                    if(nbAverageHoursNeeded <= 0 && nbMinHoursNeeded <= 0 && nbMaxHoursNeeded <= 0){
                        break;
                    }
                }
            }
            // console.log('test----2');
            // now we have to remove the days before the expected finish date from the user dates bucket
            // and redefine the date of the expected finish date to the expectedFinishDateRest
            // if this rest is equal to 0, this date can also be deleted

            const startDateMoment = new Date(estimations.startAt).getTime(),
                finishDateMoment = new Date(estimations.expectedAt).getTime();
            let cd2;

            
            for(let currentDate2 in userData.dates){

                cd2 = new Date(currentDate2).getTime();

                if(cd2 >= startDateMoment && cd2 < finishDateMoment){
                    delete userData.dates[currentDate2];
                }
                else if(cd2 >= startDateMoment && cd2 === finishDateMoment){
                    if(expectedFinishDateRest === 0){
                        delete userData.dates[currentDate2];
                    }
                    else {
                        userData.dates[currentDate2] = expectedFinishDateRest;
                    }
                }
            }
        }
        // console.log('test----3');

    }
    else {
        // console.log('test----error');
        // if no userId was saved it's because no user with the required skill was found
        // we set an error on the estimation
        estimations.error = 'no.user.with.required.skill';
    }
    // console.log('test---3');
    // we return the calculated estimations of the task
    return {
        userBucket: userBucket,
        estimations: estimations,
        dependenciesCanStartOn: (estimations.expectedAt) ? estimations.expectedAt : todayFormatted
    };

}


function getFormattedDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}

function getOrderedTaskList(tasks){
    // adding the id property to each task
    const list = _.map(tasks, (t, i) => {
        delete t.estimations;
        return {...t, taskId: i, id: i, tasktype: 'task'};
    });
    // ordering the list by index
    const completeList = _.sortBy(list, 'index');

    return getOrderedTaskListTreat(undefined, completeList);
}

function getOrderedTaskListTreat(parent, completeList){
    let list = [];

    completeList.forEach((t)=>{
        if(t.parent === parent){
            list.push(t);
            if(t.childrens){
                list = list.concat(getOrderedTaskListTreat(t.taskId, completeList));
            }
        }
    });

    return list;
}

/*eslint-enable */
